import React from 'react';
import { connect } from 'react-redux';
import StatementContentPanelOCR from 'components/feature/statement-content-panel/statement-content-panel-component-ocr';
import withURLRouter from 'withURLRouter';
import {
  setLeftSectionInView,
  clearLeftSectionInView,
} from 'store/actions/left-statement-section-content-actions';
import {
  setSectionInView,
  clearSectionInView,
} from 'store/actions/statement-content-actions';

const StatementContentPanelContainerOCR = (props) => {
  return <StatementContentPanelOCR {...props} />;
};

const mapStateToProps = ({
  data: {
    blacklineSourceGuidList,
    blacklineTargetGuidList,
    statementContent: {
      sectionIdList,
      sectionsCache: { cache, ocrCache, blacklineCache },
      elementCache,
      notesCache,
    },
    leftStatementContent: {
      leftSectionIdList,
      leftSectionsCache: { leftCache, leftOcrCache, leftBlacklineCache },
      leftElementCache,
    },
  },
  ui: {
    statementPage: {
      modes: {
        blacklineViewMode
      }
    }
  }
}) => ({
  sectionIdList,
  leftSectionIdList,
  cache,
  leftCache,
  ocrCache,
  leftOcrCache,
  elementCache,
  leftElementCache,
  blacklineCache,
  leftBlacklineCache,
  blacklineSourceGuidList,
  blacklineTargetGuidList,
  blacklineViewMode,
  notesCache,
});

const mapDispatchToProps = {
  setSectionInView,
  clearSectionInView,
  setLeftSectionInView,
  clearLeftSectionInView,
};

export default withURLRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatementContentPanelContainerOCR),
);
