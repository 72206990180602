import { createAction } from 'redux-actions';
import sizeof from 'object-sizeof';
import { getElementsBySectionRequest } from 'api/element-api';
import {
  checkCacheToPurge,
  addSectionToCache,
  addLeftSectionToCache,
} from 'store/actions/section-cache-actions';
import {
  currentBlacklineViewChangesLoading,
  currentBlacklineViewChangesLoaded,
  currentBlacklineViewChangesError,
  previousBlacklineViewChangesLoading,
  previousBlacklineViewChangesLoaded,
  previousBlacklineViewChangesError,
} from 'store/actions/blackline-view-actions';
import {
  sectionElementsLoading,
  sectionElementsLoaded,
  sectionElementsError,
  leftSectionElementsLoading,
  leftSectionElementsLoaded,
  leftSectionElementsError,
} from 'store/actions/statement-content-actions';
import {
  getOCRImageSectionSegmentsRequest,
  getOCRDataSectionSegmentsRequest,
  getBlacklineChangesData,
} from 'api/statement-content-api';
import { checkLeftCacheToPurge } from 'store/actions/left-statement-section-content-actions';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';
import { isElementHasAnyAnnotations } from 'utils/statement-content-page-utils';
import { getNotesOCR } from 'api/notes-api';
import {
  notesCacheSectionLoading,
  notesCacheSectionLoaded,
  notesCacheSectionError,
} from 'store/actions/notes-cache-actions';

export const sectionOCRImageStatementContentLoaded = createAction(
  'SECTION_OCR_IMAGE_STATEMENT_CONTENT_LOADED',
);
export const sectionOCRImageStatementContentError = createAction(
  'SECTION_OCR_IMAGE_STATEMENT_CONTENT_ERROR',
);

export const sectionOCRDataSegmentsStatementContentLoaded = createAction(
  'SECTION_OCR_DATA_SEGMENTS_STATEMENT_CONTENT_LOADED',
);
export const sectionOCRDataSegmentsStatementContentError = createAction(
  'SECTION_OCR_DATA_SEGMENTS_STATEMENT_CONTENT_ERROR',
);

export const leftSectionOCRImageStatementContentLoaded = createAction(
  'LEFT_SECTION_OCR_IMAGE_STATEMENT_CONTENT_LOADED',
);
export const leftSectionOCRImageStatementContentError = createAction(
  'LEFT_SECTION_OCR_IMAGE_STATEMENT_CONTENT_ERROR',
);

export const leftSectionOCRDataSegmentsStatementContentLoaded = createAction(
  'LEFT_SECTION_OCR_DATA_SEGMENTS_STATEMENT_CONTENT_LOADED',
);
export const leftSectionOCRDataSegmentsStatementContentError = createAction(
  'LEFT_SECTION_OCR_DATA_SEGMENTS_STATEMENT_CONTENT_ERROR',
);

export const fetchElementsBySectionOCR =
  ({ sectionId, withoutLoading = false }) =>
  (dispatch, getStore) => {
    const { data, router } = getStore();
    const {
      revision,
      annotationDisplayOptions,
      statementContent: { elementCache },
    } = data;
    const {
      location: { pathname },
    } = router;
    const isPreviewPage = isElementReportPreviewPage(pathname);
    !withoutLoading && dispatch(sectionElementsLoading({ sectionId }));
    getElementsBySectionRequest({
      sectionId,
      revisionId: revision.id,
      isPreviewPage,
    })
      .then((response) => {
        if (response.data && annotationDisplayOptions.showAll) {
          response.data = response.data.map((item) => {
            return {
              ...item,
              marker: false,
              callout:
                isElementHasAnyAnnotations(item) &&
                annotationDisplayOptions.showAll,
            };
          });
        } else if (elementCache.getCurrentSectionIds().length) {
          const elementIds = elementCache.getElementIdsBySectionId({
            sectionId,
          });
          if (elementIds.marker.length || elementIds.callout.length) {
            response.data = response.data.map((item) => {
              return {
                ...item,
                marker: elementIds.marker.includes(item.id),
                callout:
                  elementIds.callout.includes(item.id) &&
                  isElementHasAnyAnnotations(item),
              };
            });
          }
        }
        dispatch(
          sectionElementsLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(sectionElementsError({ sectionId, error }));
      });
  };

export const fetchLeftElementsBySectionOCR =
  ({ sectionId }) =>
  (dispatch, getStore) => {
    const {
      sourceStatementParams: { statementId, revisionId },
    } = getStore().ui;

    dispatch(leftSectionElementsLoading({ sectionId }));
    getElementsBySectionRequest({
      statementId: statementId,
      sectionId,
      revisionId: revisionId,
    })
      .then((response) => {
        dispatch(
          leftSectionElementsLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(leftSectionElementsError({ sectionId, error }));
      });
  };

export const fetchCurrentBlacklineDataBySectionOCR =
  ({ sectionId, blacklineGuidDetails }) =>
  (dispatch, getStore) => {
    const { revision, selectedProject } = getStore().data;
    dispatch(currentBlacklineViewChangesLoading({ sectionId }));
    getBlacklineChangesData({
      clientId: parseInt(selectedProject.id),
      revisionId: revision.id,
      guid: blacklineGuidDetails.storageGuid,
      keyVersion: blacklineGuidDetails.keyVersion,
      contentType: blacklineGuidDetails.contentType,
    })
      .then((response) => {
        dispatch(
          currentBlacklineViewChangesLoaded({
            response: { data: response.data.page_content },
            sectionId: response.data.page_number,
          }),
        );
      })
      .catch((error) => {
        dispatch(currentBlacklineViewChangesError({ sectionId, error }));
      });
  };

export const fetchPreviousBlacklineDataBySectionOCR =
  ({ sectionId, blacklineGuidDetails }) =>
  (dispatch, getStore) => {
    const { revision, selectedProject } = getStore().data;

    dispatch(previousBlacklineViewChangesLoading({ sectionId }));
    getBlacklineChangesData({
      clientId: parseInt(selectedProject.id),
      revisionId: revision.id,
      guid: blacklineGuidDetails.storageGuid,
      keyVersion: blacklineGuidDetails.keyVersion,
      contentType: blacklineGuidDetails.contentType,
    })
      .then((response) => {
        dispatch(
          previousBlacklineViewChangesLoaded({
            response: { data: response.data.page_content },
            sectionId: response.data.page_number,
          }),
        );
      })
      .catch((error) => {
        dispatch(previousBlacklineViewChangesError({ sectionId, error }));
      });
  };

const calculateSizeAndAddSectionToCache =
  ({ sectionId }) =>
  async (dispatch, getStore) => {
    const { ocrCache } = getStore().data.statementContent.sectionsCache;
    const ocrSectionMemorySize = sizeof(ocrCache.get(sectionId));
    dispatch(
      addSectionToCache({
        sectionId,
        sectionMemorySize: ocrSectionMemorySize,
      }),
    );
  };

const calculateSizeAndAddSectionToLeftCache =
  ({ sectionId }) =>
  async (dispatch, getStore) => {
    const { leftOcrCache } =
      getStore().data.leftStatementContent.leftSectionsCache;
    const ocrSectionMemorySize = sizeof(leftOcrCache.get(sectionId));
    dispatch(
      addLeftSectionToCache({
        sectionId,
        sectionMemorySize: ocrSectionMemorySize,
      }),
    );
  };

export const fetchSectionOCRSegmentsStatementContent =
  ({ sectionMetaData }) =>
  async (dispatch, getStore) => {
    const { revision, selectedProject } = getStore().data;
    const { imageGuid, imageKeyVersion, jsonGuid, jsonKeyVersion } =
      sectionMetaData;

    getOCRImageSectionSegmentsRequest({
      clientId: parseInt(selectedProject.id),
      revisionId: revision.id,
      guid: imageGuid,
      keyVersion: imageKeyVersion,
    })
      .then((imgResponse) => {
        dispatch(
          sectionOCRImageStatementContentLoaded({
            image: URL.createObjectURL(new Blob([imgResponse.data])),
            sectionId: sectionMetaData.sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          sectionOCRImageStatementContentError({
            error,
          }),
        );
      });

    getOCRDataSectionSegmentsRequest({
      clientId: parseInt(selectedProject.id),
      revisionId: revision.id,
      guid: jsonGuid,
      keyVersion: jsonKeyVersion,
    })
      .then((ocrResponse) => {
        dispatch(
          sectionOCRDataSegmentsStatementContentLoaded({
            ocr: ocrResponse.data,
            sectionId: sectionMetaData.sectionId,
          }),
        );
        dispatch(
          calculateSizeAndAddSectionToCache({
            sectionId: sectionMetaData.sectionId,
          }),
        );
        dispatch(checkCacheToPurge());
      })
      .catch((error) => {
        dispatch(
          sectionOCRDataSegmentsStatementContentError({
            error,
          }),
        );
      });
  };

export const fetchLeftSectionOCRSegmentsStatementContent =
  ({ sectionMetaData }) =>
  async (dispatch, getStore) => {
    const {
      sourceStatementParams: { projectId, revisionId },
    } = getStore().ui;

    const { imageGuid, imageKeyVersion, jsonGuid, jsonKeyVersion } =
      sectionMetaData;
    getOCRImageSectionSegmentsRequest({
      clientId: parseInt(projectId),
      revisionId: revisionId,
      guid: imageGuid,
      keyVersion: imageKeyVersion,
    })
      .then((imgResponse) => {
        dispatch(
          leftSectionOCRImageStatementContentLoaded({
            image: URL.createObjectURL(new Blob([imgResponse.data])),
            sectionId: sectionMetaData.sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          leftSectionOCRImageStatementContentError({
            error,
          }),
        );
      });

    getOCRDataSectionSegmentsRequest({
      clientId: parseInt(projectId),
      revisionId: revisionId,
      guid: jsonGuid,
      keyVersion: jsonKeyVersion,
    })
      .then((ocrResponse) => {
        dispatch(
          leftSectionOCRDataSegmentsStatementContentLoaded({
            ocr: ocrResponse.data,
            sectionId: sectionMetaData.sectionId,
          }),
        );
        dispatch(
          calculateSizeAndAddSectionToLeftCache({
            sectionId: sectionMetaData.sectionId,
          }),
        );
        dispatch(checkLeftCacheToPurge());
      })
      .catch((error) => {
        dispatch(
          leftSectionOCRDataSegmentsStatementContentError({
            error,
          }),
        );
      });
  };

export const fetchnotesBySectionOCR =
  ({ sectionId, withoutLoading = false }) =>
  (dispatch, getStore) => {
    const { data } = getStore();
    const { revision } = data;
    !withoutLoading && dispatch(notesCacheSectionLoading({ sectionId }));
    getNotesOCR({ revisionId: revision.id, sectionId })
      .then((response) => {
        dispatch(
          notesCacheSectionLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(notesCacheSectionError({ sectionId, error }));
      });
  };

export const fetchAllOCRSectionRenderingData =
  ({ sectionMetaData, isLeftView = false }) =>
  (dispatch, getStore) => {
    const { router } = getStore();
    const {
      location: { pathname },
    } = router;
    const isPreviewPage = isElementReportPreviewPage(pathname);

    if (isLeftView) {
      dispatch(
        fetchLeftSectionOCRSegmentsStatementContent({
          sectionMetaData,
        }),
      );

      dispatch(
        fetchLeftElementsBySectionOCR({
          sectionId: sectionMetaData.sectionId,
        }),
      );
    } else {
      dispatch(
        fetchSectionOCRSegmentsStatementContent({
          sectionMetaData,
        }),
      );

      dispatch(
        fetchElementsBySectionOCR({ sectionId: sectionMetaData.sectionId }),
      );

      !isPreviewPage &&
        dispatch(
          fetchnotesBySectionOCR({ sectionId: sectionMetaData.sectionId }),
        );
    }
  };
